import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { authByToken, loginCheck } from '../api/login';
import { HOME_PATH, LOG_IN_PATH, RESET_PASSWORD_CONFIRM_PATH } from '../router';
import { accountSelector, setAccount, setRole } from '../store';
import { Roles } from '../store/types';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { isAuth } = useAppSelector(accountSelector);

  const checkToken = async (token: string) => {
    const { status, payload } = await authByToken(token);
    if (status === 'success') {
      const { email, is_confirmed: confirmed } = payload;
      dispatch(setAccount({ isAuth: true, email, confirmed }));
      dispatch(setRole(Roles.Unknown));
      navigate(HOME_PATH);
    } else {
      dispatch(setAccount({ isAuth: false }));
      navigate(LOG_IN_PATH);
    }
  };

  const checkAuth = async () => {
    const { status, payload } = await loginCheck();
    if (status === 'success') {
      const { is_staff: isStaff } = payload;
      dispatch(setAccount({ isAuth: true, isStaff, ...payload }));
    } else dispatch(setAccount({ isAuth: false }));
  };
  useEffect(() => {
    if (pathname === `/${LOG_IN_PATH}/${RESET_PASSWORD_CONFIRM_PATH}/`) {
      return;
    }
    if (token) {
      localStorage.setItem('token', token);
      checkToken(token);
      return;
    }
    checkAuth();
  }, []);

  useEffect(() => {
    if (isAuth && pathname.includes(LOG_IN_PATH)) {
      navigate(HOME_PATH);
      return;
    }
    if (isAuth === false && !pathname.includes(LOG_IN_PATH)) {
      navigate(LOG_IN_PATH);
      return;
    }
    if (isAuth === false && pathname.includes(LOG_IN_PATH)) {
      return;
    }
    if (isAuth && !pathname.includes(LOG_IN_PATH)) {
      return;
    }
  }, [isAuth, pathname]);
};
