import { request } from '../utils/fetchHandler';
import {
  AddUserResponse,
  EditUserResponse,
  GetRolesResponse,
  UserDataResponse,
} from './user/types';

interface IUserData {
  first_name: string;
  last_name: string;
  middle_name: string | null;
  phone: string | null;
}

export const addUserData = async (
  userData: IUserData,
): Promise<{ status: string; payload: AddUserResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/user/registration-completion/`,
    { credentials: 'include' },
    'POST',
    JSON.stringify(userData),
  );

  return { status, payload };
};

export const userData = async (
  id: number | null | string | undefined,
): Promise<{ status: string; payload: UserDataResponse }> => {
  const { status, payload } = await request(`${process.env.REACT_APP_BACK_END}/user/${id}/`, {
    credentials: 'include',
  });

  return { status, payload };
};

export const profileEdit = async (
  userData: IUserData,
  id: number | null,
): Promise<{ status: string; payload: EditUserResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/user/${id}/`,
    { credentials: 'include' },
    'PUT',
    JSON.stringify(userData),
  );

  return { status, payload };
};

export const getRoles = async (): Promise<{ status: string; payload: GetRolesResponse }> => {
  const { status, payload } = await request(`${process.env.REACT_APP_BACK_END}/user/roles/`, {
    credentials: 'include',
  });

  return { status, payload };
};

export const hidePhone = async (isHide: boolean, id: number | null) => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/user/${id}/hide-phone/`,
    { credentials: 'include' },
    'PATCH',
    JSON.stringify({ hide_phone: isHide }),
  );

  return { status };
};
