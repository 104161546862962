import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { popupSelector } from '../../../../store/selectors/popupSelector';
import { closePopup } from '../../../../store/slices/popupSlice';
import { PopupTypes } from '../../../../store/types';
import {
  AddAnyModelToSpecificAlliancePopUp,
  AddModelToAnyAlliancePopup,
  AddProjectToPortfolio,
  AddWeightEditWeightPopUp,
  AddWeightOptionsPopUp,
  ConfirmPopup,
  EditProject,
  ErrorPopup,
  FeedbackPopUp,
  InvitationCommentPopUp,
  InvitationPopUp,
  JoinAlliancePopUp,
  ModelToAllianceApplicationPopUp,
  Overlay,
  ProjectDetailedInfo,
  RequestToOwnerPopUp,
  StartFLPopup,
  SuccessPopUp,
  WeightInAlliancePreviewPopUp,
  WeightPopup,
  WeightToAllianceApplicationPopUp,
  ShowEmployeeMetricsPopup,
  EditAggregatedWeightPopup,
  DockerImageGuidePopup,
  WeightForBuildingImagePopup,
  ProlongRoundPopup,
} from '../../../../ui';
import { AddModelToCreatedAlliancePopup } from '../AddModelToCreatedAlliancePopup/AddModelToCreatedAlliancePopup';
import { PauseAppealPopup } from '../PauseAppealPopup/PauseAppealPopup';
import { StartOnboardingPopup } from '../StartOnboardingPopup/StartOnboardingPopup';

export const PopupArea = () => {
  const popup = useAppSelector(popupSelector);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closePopup());
  };

  const PopupDisplay = () => {
    switch (popup.type) {
      case PopupTypes.None:
        return <></>;
      case PopupTypes.Error:
        return <ErrorPopup isReload={popup.data.isReload} />;
      case PopupTypes.Feedback:
        return <FeedbackPopUp onClose={handleClose} />;
      case PopupTypes.Success:
        return (
          <SuccessPopUp onClose={handleClose} title={popup.data.title} info={popup.data.info} />
        );
      case PopupTypes.Invite:
        return <InvitationPopUp data={popup.data} onClose={handleClose} />;
      case PopupTypes.InviteComment:
        return <InvitationCommentPopUp data={popup.data} />;
      case PopupTypes.JoinComment:
        return <RequestToOwnerPopUp data={popup.data} />;
        case PopupTypes.ProlongRound:
        return <ProlongRoundPopup roundId={popup.data.roundId} onClose={handleClose} />;
      case PopupTypes.StartFL:
        return (
          <StartFLPopup
            paramsData={popup.data.paramsData}
            modelId={popup.data.modelId}
            modelName={popup.data.modelName}
            onClose={handleClose}
            weightsCount={popup.data.weightsCount}
            allianceId={popup.data.allianceId}
            isAdmin={Boolean(popup.data.isAdmin)}
          />
        );
      case PopupTypes.EditAggregatedWeight:
        return (
          <EditAggregatedWeightPopup
            onClose={handleClose}
            modelName={popup.data?.modelName}
            weightData={{
              name: popup.data?.weightData?.name,
              description: popup.data?.weightData?.description,
              id: popup.data?.weightData?.id,
            }}
          />
        );
      case PopupTypes.DetailedWeight:
        return (
          <WeightPopup
            weight={popup.data.weightData}
            modelName={popup.data.modelName}
            title={popup.data.title}
            onDownloadClick={popup.data.onDownloadClick}
            onDeleteClick={popup.data.onDeleteClick}
            controlsType={popup.data.controlsType}
            isAggregatedWeight={popup.data.isAggregatedWeight}
            isPaid={popup.data.isPaid}
          />
        );
      case PopupTypes.AddOrEditWeight:
        return (
          <AddWeightEditWeightPopUp
            onClose={handleClose}
            defaultData={popup.data.weightData}
            weightId={popup.data.weightId}
            modelId={popup.data.modelId}
            modelName={popup.data.modelName}
            isOwner={popup.data.isOwner}
            paramsData={popup.data.paramsData}
            metricsData={popup.data.metricsData}
            fileData={popup.data.fileData}
            isPending={popup.data.isPending}
          />
        );
      case PopupTypes.Join:
        return <JoinAlliancePopUp data={popup.data} onClose={handleClose} />;
      case PopupTypes.AddWeight:
        return (
          <AddWeightOptionsPopUp
            isOwner={popup.data.isOwner}
            onClose={handleClose}
            modelName={popup.data.modelName}
            defaultData={popup.data.weightData}
            modelId={popup.data.modelId}
            allianceId={popup.data.allianceId}
          />
        );
      case PopupTypes.WeightInAlliancePreview:
        return <WeightInAlliancePreviewPopUp modelName={popup.data.modelName} id={popup.data.id} />;
      case PopupTypes.AddAnyModelToSpecificAlliance:
        return (
          <AddAnyModelToSpecificAlliancePopUp
            allianceName={popup.data.allianceName}
            allianceId={popup.data.allianceId}
            isOwner={popup.data.isOwner}
            onClose={handleClose}
          />
        );
      case PopupTypes.AddCurrentModelToCreatedAlliance:
        return <AddModelToCreatedAlliancePopup id={popup.data.modelId} onClose={handleClose} />;
      case PopupTypes.AddCurrentModel:
        return <AddModelToAnyAlliancePopup id={popup.data.modelId} onClose={handleClose} />;
      case PopupTypes.ModelToAllianceApplication:
        return (
          <ModelToAllianceApplicationPopUp
            id={popup.data.id}
            isReadOnly={Boolean(popup.data.isReadOnly)}
            onStateChange={popup.data.onStateChange}
            onClose={handleClose}
          />
        );
      case PopupTypes.WeightToAllianceApplication:
        return (
          <WeightToAllianceApplicationPopUp
            id={popup.data.id}
            modelName={popup.data.modelName}
            isReadOnly={Boolean(popup.data.isReadOnly)}
            onStateChange={popup.data.onStateChange}
            onClose={handleClose}
          />
        );
      case PopupTypes.AddProjectToPortfolio:
        return <AddProjectToPortfolio onClose={handleClose} onAction={() => popup.onAction()} />;
      case PopupTypes.EditProject:
        return (
          <EditProject
            onClose={handleClose}
            onAction={() => popup.onAction()}
            defaultValues={{
              name: popup.data.projectData?.name,
              description: popup.data.projectData?.description,
            }}
            id={popup.data.projectData?.id}
            filesData={popup.data.projectData?.files}
            linkData={popup.data.projectData?.link}
          />
        );
      case PopupTypes.ProjectDetailedInfo:
        return (
          <ProjectDetailedInfo project={popup.data.projectData} onAction={() => popup.onAction()} />
        );
      case PopupTypes.ShowEmployeeMetricsPopup:
        return <ShowEmployeeMetricsPopup metrics={popup.data.metricsRoundData ?? []} />;
      case PopupTypes.StartOnboarding:
        return <StartOnboardingPopup onClose={handleClose} />;
      case PopupTypes.DockerImageGuide:
        return <DockerImageGuidePopup name={String(popup.data.name)} />;
      case PopupTypes.WeightForBuildingImage:
        return (
          <WeightForBuildingImagePopup onClose={handleClose} modelId={Number(popup.data.modelId)} />
        );
      case PopupTypes.PauseAppealPopup:
        return (
          <PauseAppealPopup
            id={popup.data.id || 0}
            onClose={handleClose}
            onAction={() => popup.onAction()}
          />
        );
      default:
        return (
          <ConfirmPopup
            type={popup.type}
            data={popup.data}
            onClose={handleClose}
            onConfirm={popup.onAction}
            onCancel={popup.onCancel}
          />
        );
    }
  };
  return (
    <Overlay onClose={handleClose} onCancel={popup.onCancel}>
      {PopupDisplay()}
    </Overlay>
  );
};
