import { FAILURE, SUCCESS, request } from '../utils/fetchHandler';
import { ResetPasswordRequestResponse, ValidateTokenResponse } from './auth/types';

interface IRequest {
  email: string;
}

export const resetPasswordRequest = async (
  email: IRequest,
): Promise<
  | { status: typeof SUCCESS; payload: { success: string } }
  | { status: typeof FAILURE; payload: ResetPasswordRequestResponse }
> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/reset-password-request/`,
    {},
    'POST',
    JSON.stringify(email),
  );

  return { status, payload };
};

export const validateToken = async (
  token: string | null,
): Promise<
  | { status: typeof SUCCESS; payload: ValidateTokenResponse }
  | { status: typeof FAILURE; payload: { detail: string } }
> => {
  const { payload, status } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/validate-token/${token}/`,
  );

  return { payload, status };
};

interface IConfirm {
  token: string | null;
  password: string;
  password_confirmation: string;
}

export const resetPasswordConfirm = async (userData: IConfirm): Promise<string> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/reset-password-confirm/?token=${userData.token}`,
    {},
    'POST',
    JSON.stringify(userData),
  );

  return status;
};
