import { useRef, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Reaptcha from 'reaptcha';

import { useAppDispatch, useAppSelector, useToggle } from '../../../../hooks';
import { accountSelector, openErrorPopup, setAccount } from '../../../../store';
import { PasswordInput, Button, Input } from '../../../../ui';
import { loginSchema } from '../../../../schemas/validationSchemas';
import { logIn } from '../../../../api/login';
import { HOME_PATH, LOG_IN_PATH, SIGN_IN_PATH } from '../../../../router';
import { isError } from '../../../../utils/formatData';
import { isCaptchaActive } from '../../../../utils';

import styles from '../Form.module.scss';

export const sitekey = '6LfUeQgkAAAAABSdQF9uSpoX71sNh5We8Ik76PGB';

export const LogInForm = () => {
  const { state: isPasswordShown, toggle: togglePassword } = useToggle<boolean>(false);
  const [token, setToken] = useState<string>(isCaptchaActive ? '' : 'token');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    setError,
    formState: { errors, isValid },
    register,
    watch,
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onTouched',
  });

  const captcha = useRef<null | Reaptcha>(null);

  const { isCookieAccepted } = useAppSelector(accountSelector);

  const passwordValue = watch('loginPassword');

  const onSubmit: SubmitHandler<FieldValues> = async ({ email, loginPassword }) => {
    const userData = {
      email,
      password: loginPassword,
      recaptcha: token,
    };

    if (!isCookieAccepted) {
      return;
    }

    const { status, payload, code } = await logIn(userData);

    if (status === 'success') {
      const { id, email, is_confirmed: confirmed, is_staff: isStaff } = payload;
      dispatch(setAccount({ isAuth: true, id, email, confirmed, isStaff }));
      navigate(HOME_PATH);
    } else {
      if ((code === 403 || code === 404) && payload.detail) {
        setError(code === 403 ? 'loginPassword' : 'email', {
          type: 'custom',
          message: payload.detail,
        });
        if (captcha.current) captcha.current.reset();
        setToken('');
      } else dispatch(openErrorPopup());
    }
  };

  const handleVerify = (token: string) => {
    setToken(token);
  };

  const handleExpire = () => {
    setToken('');
  };

  return (
    <form className={styles.form} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <h2 className={styles.title}>{t('pages.authPage.logInForm.title')}</h2>
      <h3 className={styles.subtitle}>
        <Trans
          i18nKey="pages.authPage.logInForm.subtitle"
          components={{
            SingInLink: <NavLink to={`/${LOG_IN_PATH}/${SIGN_IN_PATH}`} />,
          }}
        />
      </h3>
      <div className={styles.container}>
        <Input
          id="email"
          label={t('pages.authPage.logInForm.labels.email')}
          register={register}
          helperText={errors.email?.message}
          isDisabled={false}
          maxLength={50}
        />
        <PasswordInput
          id="loginPassword"
          label={t('pages.authPage.logInForm.labels.password')}
          type={isPasswordShown ? 'text' : 'password'}
          onClick={togglePassword}
          register={register}
          helperText={errors.loginPassword?.message}
          value={passwordValue}
          isForgotten={true}
        />
      </div>
      <div className={styles.captcha}>
        <Reaptcha
          ref={captcha}
          sitekey={sitekey}
          onVerify={recaptchaResponse => {
            handleVerify(recaptchaResponse);
          }}
          onExpire={handleExpire}
        />
      </div>
      <Button isDisabled={isError(errors) || !isValid || !token} type="submit">
        {t('buttons.enter')}
      </Button>
    </form>
  );
};
