import cn from 'classnames';

import { Tab } from './Tab/Tab';
import { TabType } from './types';

import styles from './Tabs.module.scss';

export const Tabs = ({
  tabs,
  style = 'secondary',
  indexRoute,
}: {
  tabs: TabType[];
  style?: 'primary' | 'secondary';
  indexRoute: string;
}) => (
  <ul className={cn(styles.container, style === 'primary' && styles.primary)}>
    {tabs.map((tab: TabType, index: number) => (
      <Tab indexRoute={indexRoute} tab={tab} key={index} style={style} />
    ))}
  </ul>
);
