import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { modelsApi } from '../../../../api/ModelsApi';
import { setNotification } from '../../../../store';
import { useAppDispatch } from '../../../../hooks';
import { AddWeight } from './AddWeight/AddWeight';
import { AddAlliance } from './AddAlliance/AddAlliance';
import { DataType, PropTypes } from './types';
import { NotificationTypes } from '../../../../store/types';

import styles from './AddModelToAnyAlliancePopup.module.scss';

export const AddModelToAnyAlliancePopup = ({ id, onClose }: PropTypes) => {
  const [data, setData] = useState<DataType>({
    modelId: id,
    modelName: '',
    allianceId: -1,
    weightId: null,
    weightName: '',
    allianceName: '',
  });
  const [currentStep, setCurrentStep] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [addModelCopyToAllianceOwner] = modelsApi.usePostAddModelCopyToAllianceAsOwnerMutation();
  const [addModelCopyToAllianceUser] = modelsApi.usePostAddModelCopyToAllianceAsUserMutation();

  const handleChangeStep = (action: 'next' | 'back') => {
    setCurrentStep(prev => (action === 'next' ? (prev += 1) : (prev -= 1)));
  };

  const handleModelName = (name: string) => {
    setData({ ...data, modelName: name });
  };

  const handleCheckWeight = (id: number | null, name: string | undefined) => {
    setData({ ...data, weightId: id, weightName: name });
  };

  const handleCheckAlliance = (id: number, name: string) => {
    setData({ ...data, allianceId: id, allianceName: name });
  };

  const handleSubmit = (userStatus: 'owner' | 'user') => {
    const isOwner = userStatus === 'owner';
    const modelData = {
      allianceId: data.allianceId,
      checkedModelId: data.modelId,
      checkedWeightId: data.weightId,
      isOwner,
    };
    const request = isOwner ? addModelCopyToAllianceOwner : addModelCopyToAllianceUser;
    request(modelData)
      .unwrap()
      .then(res => {
        onClose();
        if (isOwner && data.weightId) {
          dispatch(
            setNotification({
              type: NotificationTypes.ModelAndWeightAddedToAlliance,
              data: {
                modelName: res.new_name,
                weightName: data.weightName,
                allianceName: data.allianceName,
              },
            }),
          );
        }
        if (isOwner && !data.weightId) {
          dispatch(
            setNotification({
              type: NotificationTypes.ModelAddedToAlliance,
              data: {
                modelName: data.modelName,
                allianceName: data.allianceName,
              },
            }),
          );
        }
        if (!isOwner && data.weightId) {
          dispatch(
            setNotification({
              type: NotificationTypes.AddModelAndWeightToAllianceRequest,
              data: {
                modelName: data.modelName,
                weightName: data.weightName,
                allianceName: data.allianceName,
              },
            }),
          );
        }
        if (!isOwner && !data.weightId) {
          dispatch(
            setNotification({
              type: NotificationTypes.AddModelToAllianceRequest,
              data: {
                modelName: data.modelName,
                allianceName: data.allianceName,
              },
            }),
          );
        }
      });
  };

  const FormDisplay = () => {
    if (currentStep === 0) {
      return (
        <AddWeight
          id={id}
          onClose={onClose}
          onCheck={(id, name) => handleCheckWeight(id, name)}
          setModelName={name => handleModelName(name)}
          onNextStep={() => handleChangeStep('next')}
        />
      );
    }
    if (currentStep === 1) {
      return (
        <AddAlliance
          model={data.modelId}
          onBack={() => handleChangeStep('back')}
          onSubmit={(userStatus: 'owner' | 'user') => handleSubmit(userStatus)}
          onCheck={(id: number, name: string) => handleCheckAlliance(id, name)}
          isValid={data.allianceId > 0}
        />
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h2 className={styles.title}>{t('popups.addSpecificModelToAnyAlliance.title')}</h2>
        {currentStep === 0 ? (
          <p className={styles.subtitle}>
            {t('popups.addSpecificModelToAnyAlliance.firstStep.subtitle')}
          </p>
        ) : (
          <p className={styles.subtitle}>
            <Trans
              i18nKey="popups.addSpecificModelToAnyAlliance.secondStep.subtitle"
              values={{ modelName: data.modelName, weightsName: data.weightName }}
              components={{ span: <span className={styles.bold} /> }}
            />
          </p>
        )}
      </div>
      {FormDisplay()}
    </div>
  );
};
