import { createSlice } from '@reduxjs/toolkit';
import { OrganizationType } from '../types';

const initialState: OrganizationType = {
  isLoading: true,
  id: null,
  name: null,
  position: null,
  inn: null,
  industry: null,
  country: null,
  region: null,
  description: null,
  status: null,
  ba: {
    firstName: null,
    middleName: null,
    lastName: null,
    phone: null,
    email: null,
  },
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    addOrganizationInfo(state, action) {
      state.name = action.payload.name;
      state.position = action.payload.position;
      state.status = action.payload.status;
      state.ba.firstName = action.payload.firstName;
      state.ba.lastName = action.payload.lastName;
      state.ba.middleName = action.payload.middleName;
      state.ba.phone = action.payload.phone;
      state.ba.email = action.payload.email;
      state.inn = action.payload.inn;
      state.industry = action.payload.industry;
      state.isLoading = false;
      state.id = null;
      state.country = null;
      state.region = null;
    },
    addBaOrganizationInfo(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.position = action.payload.position;
      state.inn = action.payload.inn;
      state.industry = action.payload.industry;
      state.country = action.payload.country;
      state.region = action.payload.region;
      state.description = action.payload.description;
      state.status = null;
      state.ba.firstName = null;
      state.ba.lastName = null;
      state.ba.middleName = null;
      state.ba.phone = null;
      state.ba.email = null;
      state.isLoading = false;
    },
    editOrganizationInfo(state, action) {
      state.position = action.payload.position;
      state.inn = action.payload.inn;
      state.industry = action.payload.industry;
      state.country = action.payload.country;
      state.region = action.payload.region;
      state.description = action.payload.description;
    },
    editOrganizationLoadingState(state) {
      state.isLoading = !state.isLoading;
    },
    removeOrganizationInfo(state) {
      state.id = null;
      state.name = null;
      state.position = null;
      state.status = null;
      state.ba.firstName = null;
      state.ba.lastName = null;
      state.ba.middleName = null;
      state.ba.phone = null;
      state.ba.email = null;
      state.inn = null;
      state.industry = null;
      state.country = null;
      state.region = null;
      state.description = null;
      state.isLoading = true;
    },
  },
});

export const {
  addOrganizationInfo,
  addBaOrganizationInfo,
  removeOrganizationInfo,
  editOrganizationInfo,
  editOrganizationLoadingState,
} = organizationSlice.actions;
export default organizationSlice.reducer;
