import { ModelsIcon, PaidIcon, ProvidedIcon } from '../../../../assets/svg';

import styles from './ModelsIconBlock.module.scss';

export const ModelsIconBlock = ({
  isPaid = false,
  isJointMl = false,
}: {
  isPaid: boolean;
  isJointMl: boolean;
}) => {
  return (
    <div className={styles.container}>
      {isJointMl ? <ProvidedIcon /> : <ModelsIcon />}
      {isPaid && <PaidIcon isSmall />}
    </div>
  );
};
