import { t } from 'i18next';
import { IModel } from './types';

export const initialModelData: IModel = {
  id: 0,
  data: {
    name: '',
    type: '',
    category: '',
    task: '',
    description: '',
    tags: [],
    attachments: [],
    modelParams: {},
    datasetParams: {},
    testParams: {},
    trainParams: {},
    link: { name: null, link: '' },
    create: '',
    update: '',
    isFavorite: false,
    isPrivate: false,
    isPaid: false,
    isJointMl: false,
    hasFl: false,
    reports: [],
    hasWeights: false,
  },
  alliance: null,
  state: 'ready',
  role: '',
  owner: null,
};

export const initialTabs = [
  { name: 'Карточка модели', tab: 'info', index: true },
  { name: 'Версии весов', tab: 'weights', isDisabled: false },
  { name: 'Исходный код', tab: 'sources', isDisabled: false },
];

export const accordionData = {
  title: 'Какие документы следует добавить?',
  content: [
    'Пример ETL-скрипта',
    'Инструкцию о развертывании и использовании ML-модели на устройстве пользователя',
    'Паспорт ML-модели, с ее входными и выходными параметрами',
  ],
};

export const getBackTitle = (isToAlliance: boolean, isToUsersMls: boolean, isToMain: boolean) => {
  if (isToAlliance) return t('navigation.toAlliance');
  if (isToUsersMls) return t('navigation.toUserModelsList');
  if (isToMain) return t('navigation.toMainPage');
  return t('navigation.toModelsList');
};
