import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkResend, resendAuth } from '../../../api/register';
import { authByToken } from '../../../api/login';
import { Roles } from '../../../store/types';
import { useAppDispatch } from '../../../hooks';
import { openErrorPopup, setRole } from '../../../store';
import { LOG_IN_PATH } from '../../../router';
import { Button } from '../../../ui';
import { formatTime } from '../../../utils/formatTime';
import { DEFAULT_TIME, resendText } from './constants';

import styles from './ResendControl.module.scss';

export const ResendControl = ({ text }: { text: string }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const dispatch = useAppDispatch();

  const handleTokenCheck = async (token: string) => {
    const { status } = await authByToken(token);
    if (status === 'success') {
      dispatch(setRole(Roles.Unknown));
    } else navigate(LOG_IN_PATH);
  };

  const handleResend = async () => {
    const { status } = await resendAuth();
    if (status === 'success') {
      setTimeLeft(DEFAULT_TIME);
    } else dispatch(openErrorPopup());
  };

  const handleCheckResend = async () => {
    const { status, payload } = await checkResend();
    if (status === 'success') {
      setTimeLeft(
        Number(
          payload.remaining.length > 4 ? payload.remaining.substring(5, 7) : payload.remaining,
        ),
      );
    } else dispatch(openErrorPopup());
  };

  useEffect(() => {
    const TIME_OUT_ID: NodeJS.Timeout | null =
      timeLeft > 0 ? setTimeout(() => setTimeLeft(timeLeft - 1), 1000) : null;
    return () => {
      if (TIME_OUT_ID) {
        clearTimeout(TIME_OUT_ID);
      }
    };
  }, [timeLeft]);

  useEffect(() => {
    if (token) {
      handleTokenCheck(token);
      localStorage.clear();
      return;
    }
    handleCheckResend();
  }, []);

  return timeLeft === 0 ? (
    <Button onClick={handleResend} style="secondary" size="small" isResizing>
      {text}
    </Button>
  ) : (
    <span className={styles.text}>
      {resendText}
      {formatTime(timeLeft)}
    </span>
  );
};
