import { FavoriteModelsResponse } from '../../api/favorites/types';
import {
  AddedModelsResponse,
  AvailableModelsResponse,
  AvailableModelsWeightsResponse,
  AvailableWeightsResponse,
  EditWeightRequest,
  ImageInfoResponse,
  ModelWeightsResponse,
  MyModelsResponse,
  OpenModelsResponse,
  UserWeightRequestResponse,
  WeightRequestResponse,
  WeightsHistoryResponse,
} from '../../api/models/types';
import { ValuesType } from '../../pages/NewModelPage/NewModelForm/constants';
import { makeFlat, transformObjectToArray } from '../../utils/formatData';
import { resultsKey } from '../constants';

export const mapAddedModels = (payload: AddedModelsResponse[typeof resultsKey]) =>
  payload.map(model => ({
    id: model.ml_model.id,
    name: model.ml_model.name,
    type: model.ml_model.model_type,
    task: model.ml_model.task,
    alliance: model.alliance.name,
    allianceId: model.alliance.id,
    endDate: model.updated_at,
    status: model.state,
  }));

export const mapAddedWeights = (payload: WeightsHistoryResponse[typeof resultsKey]) =>
  payload.map(weight => ({
    id: weight.id,
    name: weight.weight_version,
    model: weight.ml_model.name,
    modelId: weight.ml_model.id,
    alliance: weight.alliance?.alliance,
    allianceId: weight.alliance?.alliance_id,
    endDate: weight.updated_at,
    status: weight.state,
  }));

export const mapMyModels = (payload: MyModelsResponse[typeof resultsKey]) =>
  payload.map(model => ({
    id: model.id,
    name: model.name,
    type: model.model_type,
    task: model.task,
    category: model.category,
    state: model.was_viewed ? 'viewed' : model.state,
    weights: model.weights_count,
    isPaid: model.is_required_payment,
    isJointMl: model.provided_by_platform ? model.provided_by_platform : false,
  }));

export const mapOpenModels = (payload: OpenModelsResponse[typeof resultsKey]) =>
  payload.map(model => ({
    id: model.id,
    name: model.name,
    type: model.model_type,
    task: model.task.name,
    category: model.category,
    weights: model.weights_count,
    isPaid: model.is_required_payment,
    isJointMl: model.provided_by_platform ? model.provided_by_platform : false,
    alliance: model.alliance
      ? { name: model.alliance.alliance, id: model.alliance.alliance_id }
      : null,
  }));

export const mapFavoriteModels = (payload: FavoriteModelsResponse[typeof resultsKey]) =>
  payload.map(model => ({
    id: model.ml_model.id,
    name: model.ml_model.name,
    type: model.ml_model.model_type,
    task: model.ml_model.task,
    alliance: model.ml_model?.alliance?.name ?? null,
    allianceId: model.ml_model?.alliance?.id ?? null,
    isHidden: model.ml_model?.alliance?.is_hidden ?? null,
  }));

export const mapAvailableModelWeights = (weights: AvailableModelsWeightsResponse[]) =>
  weights.map(weight => ({
    id: weight.id,
    name: weight.meta_data.name,
    size: weight.meta_data.file_size,
    description: weight.meta_data.description,
    date: weight.meta_data.created_at,
    isDisabled: weight.name_taken,
  }));

export const mapAvailableModels = (payload: AvailableModelsResponse[typeof resultsKey]) =>
  payload.map(model => ({
    id: model.id,
    task: model.task,
    name: model.name,
    type: model.model_type,
    category: model.category,
    count: model.weights_count,
    isDisabled: model.already_applied,
    isPaid: model.is_required_payment,
    isJointMl: model.provided_by_platform,
    weights: mapAvailableModelWeights(model.weights),
  }));

export const mapWeights = (payload: ModelWeightsResponse[typeof resultsKey]) =>
  payload.map(weight => ({
    id: weight.id,
    name: weight.meta_data.name,
    params: {
      model: transformObjectToArray(weight.meta_data.model_params),
      test: transformObjectToArray(weight.meta_data.test_params),
      train: transformObjectToArray(weight.meta_data.train_params),
    },
    description: weight.meta_data.description,
    date: weight.meta_data.created_at,
    size: weight.meta_data.file_size,
    state: weight.meta_data.state,
    metrics: transformObjectToArray(weight.meta_data.metrics),
    fileName: weight.meta_data.file_name,
  }));

export const mapAvailableWeights = (payload: AvailableWeightsResponse[typeof resultsKey]) =>
  payload.map(weight => ({
    id: weight.id,
    name: weight.meta_data.name,
    description: weight.meta_data.description,
    date: weight.meta_data.created_at,
    size: weight.meta_data.file_size,
    isNameTaken: weight.name_taken,
  }));

export const mapWeightsToAlliance = (payload: UserWeightRequestResponse) => {
  const {
    name,
    description,
    model_params,
    test_params,
    train_params,
    file_size,
  } = payload.meta_data;
  return {
    name,
    description,
    params: {
      model: transformObjectToArray(model_params),
      test: transformObjectToArray(test_params),
      train: transformObjectToArray(train_params),
    },
    size: file_size,
    date: payload.created_at,
  };
};

export const mapWeightsApplication = (payload: WeightRequestResponse) => {
  return {
    isNameTaken: !payload.is_free,
    name: payload.meta_data.name,
    description: payload.meta_data.description,
    size: payload.meta_data.file_size,
    params: {
      model: transformObjectToArray(payload.meta_data.model_params),
      train: transformObjectToArray(payload.meta_data.train_params),
      test: transformObjectToArray(payload.meta_data.train_params),
    },
    date: payload.meta_data.created_at,
    applicationDate: payload?.created_at || '',
  };
};

export const mapImageInfo = (payload: ImageInfoResponse) => {
  return {
    dockerImageName: payload.docker_image_name,
    weightMetaData: {
      name: payload.weight.meta_data.name,
      id: payload.weight.id,
      size: payload.weight.meta_data.file_size,
      description: payload.weight.meta_data.description,
      date: payload.weight.meta_data.created_at,
      params: {
        model: transformObjectToArray(payload.weight.meta_data.model_params),
        train: transformObjectToArray(payload.weight.meta_data.train_params),
        test: transformObjectToArray(payload.weight.meta_data.test_params),
      },
    },
    state: payload.state,
    modelName: payload.ml_model.name,
  };
};

export const mapCreateModelData = (values: ValuesType) => {
  const formattedData = {
    name: values.name,
    model_type: values.modelType,
    link: values.link.link,
    link_name: values.link.name,
    task: values.tasksType,
    tags: values.tags.length
      ? values.tags.map((tag: { name: string; id: number }) => tag.id)
      : null,
    description: values.description,
    category: values.category,
    model_params: makeFlat(values.modelParams),
    dataset_params: makeFlat(values.datasetParams),
    test_params: makeFlat(values.testParams),
    train_params: makeFlat(values.trainParams),
    is_private: values.isPrivate,
    is_required_payment: values.isPaid,
  };

  return 'providedByPlatform' in values
    ? { ...formattedData, provided_by_platform: values.providedByPlatform }
    : formattedData;
};

type WeightType = {
  name?: string;
  description?: string;
  model_params?: string;
  test_params?: string;
  train_params?: string;
  metrics?: string;
};

export const mapWeightToFormData = (data: EditWeightRequest['data']) => {
  const weightData = {} as WeightType;
  if (data.name) {
    weightData.name = data.name;
  }
  if (data.description) {
    weightData.description = data.description;
  }

  weightData.model_params = JSON.stringify(
    data?.params?.model?.length ? makeFlat(data.params.model) : null,
  );

  weightData.test_params = JSON.stringify(
    data?.params?.test?.length ? makeFlat(data.params.test) : null,
  );

  weightData.train_params = JSON.stringify(
    data?.params?.train?.length ? makeFlat(data.params.train) : null,
  );

  weightData.metrics = JSON.stringify(data?.metrics?.length ? makeFlat(data.metrics) : null);

  const formData = new FormData();
  (Object.keys(weightData) as Array<keyof typeof weightData>).forEach(key => {
    formData.append(key, weightData[key] as string);
  });

  if (data.file) {
    formData.append('weight_file', data.file[0]);
  }

  return formData;
};
