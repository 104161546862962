import { useEffect, useState } from 'react';

import { alliancesApi } from '../../../../../../api/alliancesApi';
import { IAlliance } from './type';

export const useFetchData = (
  borderPosition: string | null,
  count: number,
  id: number,
  isControlled?: boolean,
) => {
  const [alliances, setAlliances] = useState<IAlliance[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [queries, setQueries] = useState<{ page: number }>({ page: 1 });

  const [fetchAvailableAlliances] = alliancesApi.useGetAvailableListMutation();

  const getAvailableAlliances = (queries: { page: number }) => {
    fetchAvailableAlliances({
      id,
      queries,
      state: isControlled ? 'own-alliances' : 'user-alliances',
    })
      .unwrap()
      .then(data => {
        setAlliances(prev => [...prev, ...(data ?? [])]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAvailableAlliances(queries);
  }, [queries]);

  useEffect(() => {
    let maxPages = Math.ceil(count / 10);
    if (borderPosition === 'top' && queries.page < maxPages) {
      setQueries(prev => ({
        page: prev.page + 1,
      }));
    }
  }, [borderPosition]);

  return { alliances, isLoading };
};
