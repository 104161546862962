import { isRejected, MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { openReloadErrorPopup } from './slices/popupSlice';

export const errorHandling: Middleware = (api: MiddlewareAPI) => next => action => {
  if (isRejected(action)) {
    // exceptions
    const endpointName = action.meta.arg.endpointName;
    const code = action.payload.status;

    const allianceReqNames = [
      'getAllianceById',
      'getAllianceModel',
      'getAllianceByIdAsAdmin',
      'getAllianceModelRequestsAsAdmin',
    ];
    const modelReqNames = ['getModelById', 'getModelByIdAsAdmin'];

    if (endpointName === 'getFLFile' || endpointName === 'postFLFiles') {
      return next(action);
    }

    if (allianceReqNames.includes(endpointName) && (code === 403 || code === 404 || code === 423)) {
      return next(action);
    }

    if (modelReqNames.includes(endpointName) && (code === 403 || code === 404)) {
      return next(action);
    }

    if (
      (endpointName === 'postAddWeightByOwner' || endpointName === 'postAddWeightAsAdmin') &&
      code === 400
    ) {
      return next(action);
    }

    if (
      (endpointName === 'postInviteToAllianceAsAdmin' || endpointName === 'postInviteToAlliance') &&
      code === 400
    ) {
      return next(action);
    }

    if (
      (endpointName === 'patchChangeWeightNameInRequest' ||
        endpointName === 'patchChangeWeightNameInRequestAsAdmin') &&
      code === 400
    ) {
      return next(action);
    }

    if (
      (endpointName === 'patchEditModelAsAdmin' || endpointName === 'patchEditModel') &&
      code === 400
    ) {
      return next(action);
    }

    return next(openReloadErrorPopup());
  }
  return next(action);
};
