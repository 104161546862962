import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useScrollShadow } from '../../../../../../hooks';
import { InfoBlock, Loader } from '../../../../../../ui';
import { RadioButton } from '../../../../../common/Buttons/RadioButton/RadioButton';
import { useFetchData } from './useScrollData';
import { IAlliance, PropTypes } from './type';

import styles from './Form.module.scss';

export const Form = ({ id, isControlled, onCheck, count }: PropTypes) => {
  const { borderPosition, onScroll } = useScrollShadow();
  const { alliances, isLoading } = useFetchData(borderPosition, count, id, isControlled);

  const { t } = useTranslation();
  const textPath = 'popups.addAnyModelToSpecificAlliance.tabs.';

  const listContainerClassName = cn(styles.listContainer, {
    [styles.listContainerTopBorder]: borderPosition === 'top' || borderPosition === 'both',
    [styles.listContainerBottomBorder]: borderPosition === 'bottom' || borderPosition === 'both',
  });

  const handleCheck = (isAvailable: boolean, id: number, name: string) => {
    if (!isAvailable) {
      return;
    }
    onCheck(id, name);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form className={styles.form}>
      <h3 className={styles.subtitle}>
        {t(`${textPath}${isControlled ? 'created' : 'my'}.subtitle`)}
      </h3>
      {alliances.length === 0 ? (
        <p className={styles.helper}>{t(`${textPath}${isControlled ? 'created' : 'my'}.helper`)}</p>
      ) : (
        <>
          {!isControlled && <InfoBlock text={t(`${textPath}my.notice`)} />}
          <div className={listContainerClassName}>
            <ul className={styles.alliances} onScroll={onScroll}>
              {alliances.map((alliance: IAlliance) => (
                <li
                  key={alliance.id}
                  className={cn(
                    styles.alliance,
                    !alliance.isAvailable && styles.allianceDisabled,
                    !alliance.isAvailable && styles.liDisabled,
                  )}
                  onClick={() => handleCheck(alliance.isAvailable, alliance.id, alliance.name)}
                >
                  {!alliance.isAvailable && (
                    <span className={styles.hint}>
                      {t(`popups.addAnyModelToSpecificAlliance.userModelHint`)}
                    </span>
                  )}
                  <label htmlFor={alliance.id.toString()} className={styles.alliance}>
                    <RadioButton
                      id={alliance.id.toString()}
                      name={`${isControlled ? 'admin' : 'my'}`}
                      isDisabled={!alliance.isAvailable}
                    />
                    <div className={styles.col}>
                      <div className={styles.name}>
                        <p>{alliance.name}</p>
                      </div>
                      <div className={styles.industry}>
                        <p>{alliance.industry}</p>
                      </div>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </form>
  );
};
