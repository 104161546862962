import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { modelsApi } from '../../../../../api/ModelsApi';
import { adminModelApi } from '../../../../../api/adminModelApi';
import { useAppDispatch, useAppSelector, useScrollShadow } from '../../../../../hooks';
import { useScrollData } from '../../AddAnyModelToSpecificAlliancePopUp/useScrollData';
import { isAdminSelector, setNotification } from '../../../../../store';
import { Button, InfoBlock, ModelWithWeightsList, Spinner } from '../../../../../ui';
import { NotificationTypes } from '../../../../../store/types';
import { ModelPopupType } from '../../../../../types';
import { CheckedType, PropTypes } from './types';

import styles from './WeightsListPopUp.module.scss';

export const WeightsListPopUp = ({
  modelName,
  isOwner,
  allianceId,
  modelId,
  onClose,
  onBack,
}: PropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [checkedModel, setCheckedModel] = useState<CheckedType>({ name: '', id: null });
  const [checkedWeight, setCheckedWeight] = useState<CheckedType>({ name: '', id: null });
  const { borderPosition, onScroll } = useScrollShadow();
  const { data, isLoading } = useScrollData(borderPosition, allianceId ?? 0);
  const isAdmin = useAppSelector(isAdminSelector);
  const [addWeightToModel] = modelsApi.usePostAddExistentWeightToModelMutation();
  const [addWeightToModelAsAdmin] = adminModelApi.usePostAddExistentWeightToModelAsAdminMutation();

  const listContainerClassName = cn(styles.listContainer, {
    [styles.listContainerTopBorder]: borderPosition === 'top' || borderPosition === 'both',
    [styles.listContainerBottomBorder]: borderPosition === 'bottom' || borderPosition === 'both',
  });

  const handleModelClick = (id: number, name: string | undefined) => {
    if (id === checkedModel.id) {
      setCheckedModel({ name: '', id: null });
    } else {
      setCheckedModel({ ...checkedModel, id, name });
    }
  };

  const handleWeightClick = (id: number | null, name: string | undefined) => {
    setCheckedWeight({ ...checkedWeight, id, name });
  };

  const handleClick = () => {
    const data = {
      alliance: allianceId,
      ml_model: modelId,
      weight: checkedWeight.id,
    };

    const addWeight = isAdmin
      ? () => addWeightToModelAsAdmin({ data })
      : () => addWeightToModel({ data, isOwner: Boolean(isOwner) });

    addWeight()
      .unwrap()
      .then(() => {
        onClose();
        dispatch(
          setNotification({
            type:
              isOwner || isAdmin
                ? NotificationTypes.WeightAddedToModelInAlliance
                : NotificationTypes.AddWeightToModelRequest,
            data: {
              weightName: checkedWeight.name,
              modelName,
            },
          }),
        );
      });
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.title}>{t('popups.weightsList.title', { modelName })}</h2>
        <h3 className={styles.subTitle}>{t('popups.weightsList.subtitle')}</h3>
      </div>
      <div className={styles.warnWrapper}>
        {!isOwner && <InfoBlock text={t('popups.weightsList.warnInfo')} />}
        <InfoBlock text={t('popups.weightsList.warnInfoParams')} />
      </div>
      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={listContainerClassName}>
          <ul className={styles.modelsList} onScroll={onScroll}>
            {data.map((model: ModelPopupType) => (
              <ModelWithWeightsList
                model={model}
                isChecked={Boolean(model.id === checkedModel.id)}
                setCheckedModel={(id, name) => handleModelClick(id, name)}
                setCheckedWeight={(id, name) => handleWeightClick(id, name)}
                key={model.id}
                defaultWeights={model.weights}
                isOwner={isOwner}
              />
            ))}
          </ul>
        </div>
      )}
      <div className={styles.btnsWrapper}>
        <Button style="secondary" size="small" onClick={onBack}>
          {t('buttons.back')}
        </Button>
        <Button size="small" isDisabled={!checkedWeight.id} onClick={handleClick}>
          {t('popups.weightsList.add')}
        </Button>
      </div>
    </div>
  );
};
