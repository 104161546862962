import { useState } from 'react';

import { ChooseOptionPopUp } from '../ChooseOptionPopUp/ChooseOptionPopUp';
import { AddWeightEditWeightPopUp } from '../AddWeightEditWeightPopUp/AddWeightEditWeightPopUp';
import { WeightsListPopUp } from './WeightsListPopUp/WeightsListPopUp';
import { text } from './constants';
import { PropTypes } from './types';

export const AddWeightOptionsPopUp = ({
  isOwner,
  onClose,
  modelName,
  defaultData,
  modelId,
  allianceId,
}: PropTypes) => {
  const [isUserDevice, setUserDevice] = useState<boolean>(false);
  const [isUserAccount, setUserAccount] = useState<boolean>(false);
  const { title, userAccount, userDevice } = text;

  const handleBack = () => {
    setUserDevice(false);
    setUserAccount(false);
  };

  return (
    <>
      {!isUserDevice && !isUserAccount && (
        <ChooseOptionPopUp
          title={title}
          options={{
            firstOption: { text: userDevice, onClick: () => setUserDevice(true) },
            secondOption: { text: userAccount, onClick: () => setUserAccount(true) },
          }}
        />
      )}
      {isUserDevice && (
        <AddWeightEditWeightPopUp
          onClose={onClose}
          onBack={handleBack}
          isOwner={isOwner}
          modelName={modelName}
          modelId={modelId}
          allianceId={allianceId}
          defaultData={defaultData}
        />
      )}
      {isUserAccount && (
        <WeightsListPopUp
          modelName={modelName}
          modelId={modelId}
          allianceId={allianceId}
          isOwner={isOwner}
          onClose={onClose}
          onBack={handleBack}
        />
      )}
    </>
  );
};
