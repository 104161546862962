import { request } from '../utils/fetchHandler';
import {
  BaOrganizationResponse,
  IndustryListResponse,
  OrganizationCreateResponse,
  OrganizationEditResponse,
  OrganizationsListResponse,
  UserOrganizationResponse,
} from './organization/types';

interface IJoinOrganizationData {
  company: number | null;
  position: string;
}

interface INewOrganizationData {
  name: string;
  position_input: string;
  inn: string;
  industry: string;
  country: string;
  region: string;
  description: string;
}

interface IEditOrganizationData {
  inn: string;
  industry: string;
  country: string;
  region: string;
  description: string;
  position_input: string;
}

export const userCompanyData = async (id: number | string | undefined | null): Promise<{
  status: string;
  payload: UserOrganizationResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/user/${id}/company-data/`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};

export const joinToOrganization = async (
  data: IJoinOrganizationData,
): Promise<{ status: string; payload: UserOrganizationResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/user/add-company/`,
    { credentials: 'include' },
    'POST',
    JSON.stringify(data),
  );

  return { status, payload };
};

export const baCompanyData = async (): Promise<{
  status: string;
  payload: BaOrganizationResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/business-admin/company-data/`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};

export const addOrganization = async (
  organizationData: INewOrganizationData,
): Promise<{ status: string; payload: OrganizationCreateResponse; code: number | undefined }> => {
  const { status, payload, code } = await request(
    `${process.env.REACT_APP_BACK_END}/business-admin/organization/`,
    { credentials: 'include' },
    'POST',
    JSON.stringify(organizationData),
  );

  return { status, payload, code };
};

export const editOrganization = async (
  organizationData: IEditOrganizationData,
  id: number | null,
): Promise<{ status: string; payload: OrganizationEditResponse; code: number | undefined }> => {
  const { status, payload, code } = await request(
    `${process.env.REACT_APP_BACK_END}/business-admin/organization/${id}/`,
    { credentials: 'include' },
    'PATCH',
    JSON.stringify(organizationData),
  );

  return { status, payload, code };
};

export const organizationsList = async (
  value: string,
): Promise<{ status: string; payload: OrganizationsListResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/organization/?search=${value}`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};

export const industryList = async (): Promise<{
  status: string;
  payload: IndustryListResponse;
}> => {
  const { status, payload } = await request(`${process.env.REACT_APP_BACK_END}/industry/`, {
    credentials: 'include',
  });
  return { status, payload };
};
