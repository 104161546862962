import cn from 'classnames';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { modelsApi } from '../../../api/ModelsApi';
import { adminModelApi } from '../../../api/adminModelApi';
import { ALLIANCES_PATH, MODELS_PATH } from '../../../router';
import { useAppDispatch, useNavigation } from '../../../hooks';
import { openPopup, setNotification } from '../../../store';
import { NotificationTypes, PopupTypes } from '../../../store/types';
import { PaidIcon } from '../../../assets/svg';
import { MenuButton, ProvidedTitle, Status } from '../../';
import { PropTypes } from './types';

import styles from './ModelCard.module.scss';

export const ModelCard = ({
  model,
  alliance,
  weights,
  state,
  isControlled = false,
  isAdminControls = false,
}: PropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onNavigate } = useNavigation();

  const isControlsAvailable = isAdminControls || isControlled;
  const deleteModelApi = isAdminControls
    ? adminModelApi.useDeleteModelAsAdminMutation
    : modelsApi.useDeleteModelMutation;

  const [deleteModel] = deleteModelApi();

  const handleMenuOpen = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const handleDelete = () => {
    deleteModel({ id: model.id })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: NotificationTypes.ModelDeletedFromAccount,
            data: {
              modelName: model.name,
            },
          }),
        );
      });
  };

  const handleDeleteOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DeleteML,
        data: { model: model.name },
        onAction: handleDelete,
      }),
    );
  };

  const handleAddOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.AddCurrentModel,
        data: { modelId: model.id },
      }),
    );
  };

  const handleAlliance = (e: SyntheticEvent) => {
    e.stopPropagation();
    onNavigate(`/${ALLIANCES_PATH}/${alliance?.id}`, true);
  };

  const DisplayStatus = () => {
    switch (state) {
      case 'viewed':
        return (
          <p className={styles.weight}>
            <span className={styles.count}>{weights}</span>
            {t('pages.singleModelPage.weights')}
          </p>
        );
      case 'pending':
        return (
          <div className={styles.statusWithDescription}>
            <Status state={'waiting'} text={t('statuses.waiting')} />
            <span className={styles.description}>{t('pages.modelsPage.validationHint')}</span>
          </div>
        );
      case 'ready':
      case 'validated':
        return <Status state={'confirmed'} text={t('statuses.confirm')} />;
      default:
        return <Status state={'canceled'} text={t('statuses.cancel')} />;
    }
  };

  const isVerified = state === 'ready' || state === 'viewed';
  const menuButtons =
    (state === 'ready' || state === 'viewed') && !isAdminControls
      ? [
          { name: t('buttons.addToAlliance'), handler: handleAddOpen },
          { name: t('buttons.delete'), handler: handleDeleteOpen, type: 'delete' },
        ]
      : [{ name: t('buttons.delete'), handler: handleDeleteOpen, type: 'delete' }];

  const paidIconWrapStyles = cn(styles.paidIconWrap, {
    [styles.paidIconVerified]: isVerified,
  });

  const cardStyles = cn(styles.card, {
    [styles.cardValidating]: state === 'pending',
  });

  return (
    <li className={cardStyles} onClick={() => onNavigate(`/${MODELS_PATH}/${model.id}`, true)}>
      <div className={styles.mainInfo}>
        <div className={styles.title}>
          <div className={styles.rowWrap}>
            {model.isJointMl && <ProvidedTitle />}
            <h3 className={styles.name}>{model.name}</h3>
          </div>
          <div className={styles.info}>
            <p>{model.task}</p>
            <span className={styles.dot} />
            <p className={styles.category}>{model.category}</p>
          </div>
        </div>
        {isControlsAvailable && (
          <div onClick={handleMenuOpen}>
            <MenuButton buttons={menuButtons} />
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.secondaryInfo}>
        <p className={styles.type}>{model.type}</p>
        {DisplayStatus()}
      </div>
      {alliance && (
        <>
          <div className={cn(styles.divider, styles.allianceDivider)} />
          <p onClick={handleAlliance} className={styles.alliance}>
            {alliance.name}
          </p>
        </>
      )}
      {model.isPaid && (
        <div className={paidIconWrapStyles}>
          <PaidIcon isDisabled={!isVerified} />
          {isVerified && (
            <span className={styles.description}>{t('pages.modelsPage.paidModelHint')}</span>
          )}
        </div>
      )}
    </li>
  );
};
