import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ADMIN_PATH, MODELS_PATH } from '../../../../router';
import { modelsApi } from '../../../../api/ModelsApi';
import { adminModelApi } from '../../../../api/adminModelApi';
import { useAppDispatch, useAppSelector, useNavigation } from '../../../../hooks';
import { isAdminSelector, openPopup, setNotification, userSelector } from '../../../../store';
import { NotificationTypes, PopupTypes } from '../../../../store/types';
import { formatDate } from '../../../../utils/formatData';
import { DeleteIconColored } from '../../../../assets/svg';
import { InfoButton, ModelsPaidCard, TextButton, TitledInfo } from '../../../../ui';
import { Tags } from '../Tags/Tags';
import { Repo } from './Repo/Repo';
import { DockerImage } from './DockerImage/DockerImage';
import { ModelStatusListProps } from '../../types';

import styles from './ModelStatusList.module.scss';

export const ModelStatusList: FC<ModelStatusListProps> = ({
  alliance,
  data,
  id,
  state,
  isControlled,
  isInPopup,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { onNavigate } = useNavigation();
  const isAdmin = useAppSelector(isAdminSelector);
  const adminPrefix = isAdmin ? `/${ADMIN_PATH}` : '';
  const [deleteModel] = isAdmin
    ? adminModelApi.useDeleteModelAsAdminMutation()
    : modelsApi.useDeleteModelMutation();
  const { isSubscriptionActive } = useAppSelector(userSelector);

  const modelAccessStatusText = t(
    `pages.singleModelPage.${data.isPrivate ? 'closedModel' : 'openModel'}`,
  );
  const modelAccessStatusHint = t(
    `pages.singleModelPage.hints.${data.isPrivate ? 'closed' : 'open'}`,
  );

  const colClassName = cn(styles.col, {
    [styles.colNotFixed]: isInPopup,
  });
  const isError = state === 'invalid';
  const isPaidContent = !isControlled && data.isPaid && !isSubscriptionActive;

  const {
    name,
    create,
    tags,
    hasFl,
    link: { link },
    reports,
  } = data;

  const handleDelete = async () => {
    deleteModel({ id })
      .unwrap()
      .then(() => {
        onNavigate(`${adminPrefix}/${MODELS_PATH}/my`);
        dispatch(
          setNotification({
            type: NotificationTypes.ModelDeletedFromAccount,
            data: {
              modelName: name,
            },
          }),
        );
      });
  };

  const handleDeleteOpen = () => {
    dispatch(
      openPopup({
        type: alliance ? PopupTypes.DeleteAllianceML : PopupTypes.DeleteML,
        data: alliance ? { model: name, alliance: alliance.name } : { model: name },
        onAction: handleDelete,
      }),
    );
  };

  return (
    <div className={colClassName}>
      {isPaidContent && <ModelsPaidCard />}
      <div className={styles.colInner}>
        <TitledInfo
          title={t('pages.singleModelPage.date')}
          text={formatDate(new Date(create))}
          isRow={true}
          isMonochrome
        />
        {!alliance && (
          <div className={styles.row}>
            <span className={styles.modelOpen}>{modelAccessStatusText}</span>
            <InfoButton
              info={<Trans i18nKey={modelAccessStatusHint} components={{ span: <span /> }} />}
            />
          </div>
        )}
      </div>
      <div className={styles.colInner}>
        <h3 className={styles.subTitle}>{t('pages.singleModelPage.characteristics')}</h3>
        <Tags tags={tags} />
      </div>
      <Repo
        isControlled={isControlled}
        state={state}
        link={isPaidContent ? 'github.com' : link}
        isError={isError}
        reports={reports}
        isDisabled={isPaidContent}
        id={id}
      />
      {data.hasWeights && (
        <DockerImage isDisabled={isPaidContent} modelId={id} imageId={data.imageId} />
      )}
      {isControlled && (
        <div className={styles.deleteBlock}>
          <TextButton
            type="button"
            size="normal"
            style="canceled"
            iconProperty={{ icon: <DeleteIconColored />, position: 'right' }}
            isDisabled={hasFl}
            onClick={handleDeleteOpen}
          >
            {t('pages.singleModelPage.deleteText')}
          </TextButton>
        </div>
      )}
    </div>
  );
};
