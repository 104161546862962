import { useEffect } from 'react';
import { getRoles, userData } from '../api/userData';
import {
  accountSelector,
  addBaOrganizationInfo,
  addOrganizationInfo,
  addSubscriptionInfo,
  addSummary,
  addUser,
  openErrorPopup,
  setRole,
  editOrganizationLoadingState,
} from '../store';
import { Roles } from '../store/types';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { baCompanyData, userCompanyData } from '../api/organization';
import { getSummary } from '../api/summary';
import { getExpirationDays } from '../utils/';
import { mapDeveloperResume } from '../mappers/developer/developer';

export const useUserData = () => {
  const { isConfirmed, isStaff } = useAppSelector(accountSelector);
  const dispatch = useAppDispatch();

  const setUserData = async (id: number) => {
    const { status, payload } = await userData(id);
    if (status === 'success') {
      const {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        hide_phone: isPhoneHidden,
        active_subscription: subscription,
        wallet,
        phone,
        account,
      } = payload;
      const { created_at: date } = account;
      const { currency, current_amount: currentAmount } = wallet;

      dispatch(
        addUser({
          firstName,
          lastName,
          middleName,
          phone,
          id,
          date,
          isPhoneHidden,
          currentAmount,
          currency,
        }),
      );

      if (subscription) {
        const { start_date: date, end_date: expirationDate, type } = subscription;
        dispatch(
          addSubscriptionInfo({
            type,
            date,
            expirationDate,
            days: getExpirationDays(date, subscription.type),
          }),
        );
      }
    } else dispatch(openErrorPopup());
  };

  const setUserCompanyData = async (userId: number) => {
    const { status, payload } = await userCompanyData(userId);
    if (status === 'success') {
      dispatch(editOrganizationLoadingState());
      if (!payload.company_name) {
        return;
      }
      const {
        company_name: name,
        business_admin_data: baData,
        state: status,
        position,
        inn,
        industry,
      } = payload;
      const {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        email,
        phone,
      } = baData;
      dispatch(
        addOrganizationInfo({
          name,
          position,
          status,
          inn,
          industry,
          firstName,
          lastName,
          middleName,
          email,
          phone,
        }),
      );
    } else dispatch(openErrorPopup());
  };

  const setBaCompanyData = async () => {
    const { status, payload } = await baCompanyData();
    if (status === 'success') {
      dispatch(addBaOrganizationInfo(payload));
    } else dispatch(openErrorPopup());
  };

  const setSummaryData = async (id: number | null) => {
    const { status, payload } = await getSummary(id);
    if (status === 'success') {
      dispatch(addSummary(mapDeveloperResume(payload)));
    } else dispatch(openErrorPopup());
  };

  const setData = async () => {
    if (!isConfirmed) {
      dispatch(setRole(Roles.Unknown));
      return;
    }
    const { status, payload } = await getRoles();
    if (status === 'success') {
      const { user_id: userId, ba_admin: baId, developer: developerId } = payload;

      if (userId) {
        dispatch(setRole(Roles.User));
        setUserData(userId);
        !baId && setUserCompanyData(userId);
      }
      if (isStaff) {
        dispatch(setRole(Roles.SystemAdministrator));
      }
      if (baId) {
        dispatch(setRole(Roles.BusinessAdministrator));
        setBaCompanyData();
      }
      if (developerId) {
        dispatch(setRole(Roles.Developer));
        setSummaryData(developerId);
      }
      if (baId && developerId) {
        dispatch(setRole(Roles.DeveloperBA));
      }
    } else dispatch(setRole(Roles.Unknown));
  };

  useEffect(() => {
    setData();
  }, []);
};
