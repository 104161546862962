import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { CreatePasswordInput, PasswordInput, Button } from '../../../../../ui';
import { SuccessNotice } from './SuccessNotice';
import { LOG_IN_PATH } from '../../../../../router';
import { resetPasswordSchema } from '../../../../../schemas/validationSchemas';
import { useAppDispatch, useToggle } from '../../../../../hooks';
import { checkChars, getRating } from '../../../../../utils/checkPassword';
import { resetPasswordConfirm, validateToken } from '../../../../../api/resetPassword';
import { openErrorPopup } from '../../../../../store';
import { CloseIcon } from '../../../../../assets/svg';

import styles from '../../Form.module.scss';

export const NewPasswordForm = () => {
  const [isHintShown, setHintShown] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const { state: isPasswordShown, toggle: togglePassword } = useToggle<boolean>(false);
  const { state: isConfirmPasswordShown, toggle: toggleConfirmPassword } =
    useToggle<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const {
    handleSubmit,
    watch,
    getValues,
    trigger,
    formState: { isValid, errors },
    register,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
  });
  const values = watch(['password', 'passwordRepeat']);
  const [passwordValue, passwordRepeatValue] = values;

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const { status, payload } = await validateToken(token); // TODO RTKQuery
      if (status === 'success') {
        const { first_name, email } = payload;
        setUserName(first_name ? `${first_name} (${email})` : email);
      } else navigate(`/${LOG_IN_PATH}`);
    })();
  }, []);

  const onSubmit: SubmitHandler<FieldValues> = async ({ password }) => {
    const userData = {
      token,
      password,
      password_confirmation: password,
    };
    const status = await resetPasswordConfirm(userData); // TODO RTKQuery

    if (status === 'success') {
      setSuccess(true);
    } else dispatch(openErrorPopup);
  };

  const handleChange = () => {
    if (passwordRepeatValue) trigger('passwordRepeat');
  };

  return (
    <>
      {!isSuccess ? (
        <form
          className={styles.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NavLink to={`/${LOG_IN_PATH}`} className={styles.closeBtn}>
            <CloseIcon />
          </NavLink>
          <h2 className={styles.title}>{t('pages.authPage.newPasswordForm.title')}</h2>
          <div className={cn(styles.subtitle, styles.subtitleBright)}>
            {t('pages.authPage.successNotification.subtitle', { userName })}
          </div>
          <div className={styles.container}>
            <CreatePasswordInput
              id="password"
              label={t('pages.authPage.newPasswordForm.labels.password')}
              type={isPasswordShown ? 'text' : 'password'}
              onClick={togglePassword}
              register={register}
              helperText={errors.password?.message}
              value={passwordValue}
              rating={getRating(passwordValue)}
              checkedValue={checkChars(passwordValue)}
              isHintShown={isHintShown}
              setHintShown={setHintShown}
              handleChange={handleChange}
            />
            <PasswordInput
              id="passwordRepeat"
              label={t('pages.authPage.newPasswordForm.labels.confirmPassword')}
              type={isConfirmPasswordShown ? 'text' : 'password'}
              onClick={toggleConfirmPassword}
              register={register}
              helperText={errors.passwordRepeat?.message}
              value={getValues('passwordRepeat')}
            />
          </div>
          <Button isDisabled={!isValid} type="submit">
            {t('buttons.confirm')}
          </Button>
        </form>
      ) : (
        <SuccessNotice userName={userName} />
      )}
    </>
  );
};
