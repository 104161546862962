import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { alliancesApi } from '../../../../../api/alliancesApi';
import { Button } from '../../../../../ui';
import { Form } from './Form/Form';
import { Tabs } from './Tabs/Tabs';
import { PropTypes } from './types';

import styles from './AddAlliance.module.scss';

export const AddAlliance = ({ model, onSubmit, onBack, onCheck, isValid }: PropTypes) => {
  const [tab, setTab] = useState<number>(1);
  const { t } = useTranslation();

  const getAvailableAlliances = alliancesApi.useGetAvailableListCounterQuery;

  const { data: myAllianceCounter } = getAvailableAlliances({
    id: model,
    state: 'user-alliances',
  });
  const { data: adminAllianceCounter } = getAvailableAlliances({
    id: model,
    state: 'own-alliances',
  });

  useEffect(() => {
    onCheck(-1, '');
  }, [tab]);

  return (
    <div className={styles.container}>
      <Tabs
        currentTab={tab}
        myAlliancesCounter={myAllianceCounter ?? 0}
        adminAlliancesCounter={adminAllianceCounter ?? 0}
        onTab={(tab: number) => setTab(tab)}
      />
      {tab === 0 && <Form onCheck={onCheck} count={myAllianceCounter ?? 0} id={model} />}
      {tab === 1 && (
        <Form onCheck={onCheck} count={adminAllianceCounter ?? 0} id={model} isControlled />
      )}
      <div className={styles.buttons}>
        <Button style="secondary" size="small" onClick={onBack}>
          {t('buttons.back')}
        </Button>
        <Button
          size="small"
          onClick={() => onSubmit(tab === 0 ? 'user' : 'owner')}
          isDisabled={!isValid}
        >
          {tab === 0 ? t('buttons.sendRequest') : t('buttons.add')}
        </Button>
      </div>
    </div>
  );
};
