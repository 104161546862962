import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../common';
import { MIN_COUNT, STEP } from './constants';
import { flearningApi } from '../../../../api/FLearningApi';

import styles from './ProlongRoundPopup.module.scss';

type PropsType = {
  roundId: number | undefined;
  onClose: () => void;
};

export const ProlongRoundPopup = ({ roundId, onClose }: PropsType) => {
  const [count, setCount] = useState<number>(MIN_COUNT);
  const { t } = useTranslation();
  const [prolongRound] = flearningApi.usePatchProlongRoundMutation();

  const handleDecr = () => {
    setCount(st => st - STEP);
  };
  const handleIncr = () => {
    setCount(st => st + STEP);
  };

  const handleSubmit = () => {
    prolongRound({ id: Number(roundId), data: { added_hours: count } })
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('popups.prolongRound.title')}</h2>
      <p className={styles.subtitle}>{t('popups.prolongRound.subtitle')}</p>
      <div className={styles.counter}>
        <button onClick={handleDecr} disabled={count === MIN_COUNT} className={styles.btn}>
          {t('popups.prolongRound.decrement')}
        </button>
        <div className={styles.text}>{count}</div>
        <button onClick={handleIncr} className={styles.btn}>
          {t('popups.prolongRound.increment')}
        </button>
      </div>
      <div className={styles.btnWrapper}>
        <Button onClick={handleSubmit}>{t('popups.prolongRound.submitBtn')}</Button>
      </div>
    </div>
  );
};
