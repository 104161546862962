import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { TabType } from '../types';

import styles from './Tab.module.scss';

export const Tab = ({
  tab,
  style,
  indexRoute,
}: {
  tab: TabType;
  style?: 'primary' | 'secondary';
  indexRoute: string;
}) => {
  const location = useLocation();
  const locationState = location.state;
  const isIndexLocation = RegExp(`.*${indexRoute}/?$`).test(location.pathname);

  return (
    <li>
      <NavLink
        state={locationState}
        to={tab.tab}
        className={({ isActive }) => {
          const isTabActive = tab.index && isIndexLocation ? true : isActive;

          return cn(
            style === 'secondary' ? styles.tab : styles.primary,
            {
              [styles.tabSelected]: style === 'secondary' && isTabActive,
              [styles.primarySelected]: style === 'primary' && isTabActive,
              [styles.tabDisabled]: tab.isDisabled,
            },
            `${tab.tab}Tab`,
          );
        }}
      >
        <span className={styles.label}>{tab.name}</span>
        {tab.count !== undefined && (
          <span className={tab.isBlue ? styles.blueCount : styles.count}>{tab.count}</span>
        )}
        {tab.isNew && <span className={styles.new} />}
      </NavLink>
    </li>
  );
};
