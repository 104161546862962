import {
  AccountNotificationsSettingsType,
  NotificationsSettingsType,
} from '../pages/SettingsPage/Notifications/types';
import { PeriodType, TCurrency } from '../types/types';
import { IAttachment } from '../ui/components/Attachments/types';
import { ParamType } from '../ui/components/Params/types';

export type StateType = {
  account: AccountType;
  role: RoleType;
  user: UserType;
  organization: OrganizationType;
  summary: SummaryType;
  notification: NotificationType[];
  popup: PopupType;
  menuCounter: MenuCounter;
};

export type MemberType = {
  id: number;
  position: string;
  state: string;
  company_data: {
    id: number;
    name: string;
  };
  profile_data: {
    email: string;
    updated_at: string;
    created_at: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    phone: string;
  };
};

export type RoleType = string | null;

export type UserType = {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  id: number | null;
  date: string;
  isPhoneHidden: boolean;
  isSubscriptionActive: boolean;
  subscription: {
    type: PeriodType | 'none';
    days: number;
    date: string;
    expirationDate: string;
  };
  wallet: {
    currency: TCurrency;
    currentAmount: string;
  };
};

export type OrganizationType = {
  isLoading: boolean;
  id: number | null;
  name: string | null;
  position: string | null;
  inn: string | null;
  industry: string | null;
  country: string | null;
  region: string | null;
  description: string | null;
  status: string | null;
  ba: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    phone: string | null;
    email: string | null;
  };
};

export type SummaryType = {
  id: number | null;
  specialization: string | null;
  experience: string | null;
  country: string | null;
  region: string | null;
  skills: { name: string; id: number }[] | null;
  about: string | null;
  link: { name: string | null; link: string | null } | null;
  isSummaryHidden: boolean;
  isFavorite: boolean;
  profileId?: number;
};

export type NotificationType = {
  type: NotificationTypes;
  data: NotificationDataType;
  id: number;
};

export type PopupType = {
  isOpen: boolean;
  onAction: (param?: string) => void;
  onCancel?: () => void;
  type: PopupTypes;
  data: PopupDataType;
};

export type NotificationDataType = {
  allianceName?: string;
  roundName?: string;
  modelName?: string;
  weightName?: string;
  projectName?: string;
  email?: string;
  name?: string;
  textContent: string;
};

export type PopupDataType = {
  id?: number;
  industry?: string;
  name?: string;
  members?: number;
  message?: string;
  model?: string;
  weight?: string;
  weightsCount?: number;
  alliance?: string;
  counter?: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  position?: string;
  inn?: string;
  company?: string;
  email?: string;
  file?: string;
  type?: string;
  link?: string;
  isReadOnly?: boolean;
  controlsType?: 'all' | 'readOnly' | 'edit';
  weightData?: any; // TODO: type after finishing all weights updating
  isAggregatedWeight?: boolean;
  onDownloadClick?: () => void;
  onDeleteClick?: () => void;
  paramsData?: { model: ParamType[]; train: ParamType[]; test: ParamType[] };
  metricsData?: ParamType[];
  metricsRoundData?: { name: string; value: number[] }[];
  weightId?: number;
  modelId?: number;
  roundName?: string;
  modelName?: string;
  allianceName?: string;
  allianceId?: number;
  isOwner?: boolean;
  isAdmin?: boolean;
  modelData?: {
    id: number;
    name: string;
    type: string;
    task: string;
    category: string;
  };
  fileData?: any;
  onComment?: ((comment: string) => void) | undefined;
  onNameChange?: ((id: number | undefined, name: string) => void) | undefined;
  onStateChange?: (state: string) => void;
  onTrigger?: (() => void) | undefined;
  title?: string;
  info?: string;
  projectData?: {
    id: number;
    name: string;
    description: string;
    link: {
      name: string;
      link: string;
    };
    files: IAttachment[];
  };
  isReload?: boolean;
  isPending?: boolean;
  isPaid?: boolean;
  roundId?: number;
};

export enum NotificationTypes {
  Approve = 'approve',
  ApprovePlural = 'approve_plural',
  Block = 'block',
  Decline = 'decline',
  BlockPlural = 'block_plural',
  Unblock = 'unblock',
  UnblockPlural = 'unblock_plural',
  InvitationInAlliance = 'invitation_in_alliance',
  AllianceApprove = 'alliance_approve',
  AllianceDecline = 'alliance_decline',
  AllianceDeactivate = 'alliance_deactivate',
  AllianceActivate = 'alliance_activate',
  AllianceDelete = 'alliance_delete',
  ModelDeletedFromAlliance = 'model_deleted_from_alliance',
  JoinAllianceRequest = 'join_alliance_request',
  UserLeftAlliance = 'user_left_alliance',
  UserJoinedAlliance = 'user_joined_alliance',
  UserExcludedFromAlliance = 'user_excluded_from_alliance',
  UserIncludedToAlliance = 'user_included_to_alliance',
  ModelDeletedFromAccount = 'model_deleted_from_account',
  WeightDeletedFromModel = 'weight_deleted_from_model',
  WeightDeletedFromAlliance = 'weight_deleted_from_alliance',
  ModelAddedToAlliance = 'model_added_to_alliance',
  ModelAndWeightAddedToAlliance = 'model_and_weight_added_to_alliance',
  WeightAddedToModel = 'weight_added_to_model',
  WeightAddedToModelInAlliance = 'weight_added_to_model_in_alliance',
  AddModelToAllianceRequest = 'add_model_to_alliance_request',
  AddModelAndWeightToAllianceRequest = 'add_model_and_weight_to_alliance_request',
  AddWeightToModelRequest = 'add_weight_to_model_request',
  ModelAddedToUserAccount = 'model_added_to_user_account',
  ModelAndWeightAddedToUserAccount = 'model_and_weight_added_to_user_account',
  ModelToAllianceApplication = 'model_to_alliance_application',
  ProfileDataSave = 'profile_data_save',
  ModelToAllianceAccept = 'model_to_alliance_accept',
  ModelToAllianceDecline = 'model_to_alliance_decline',
  ModelAndWeightToAllianceAccept = 'model_and_weight_to_alliance_accept',
  ModelAndWeightToAllianceDecline = 'model_and_weight_to_alliance_decline',
  WeightToAllianceModelAccept = 'weight_to_alliance_model_accept',
  WeightToAllianceModelDecline = 'weight_to_alliance_model_decline',
  AddProjectToPortfolio = 'add_project_to_portfolio',
  DeleteProject = 'delete_project',
  AddToFavorites = 'add_to_favorites',
  DeleteFromFavorites = 'delete_from_favorites',
  RoundHasStarted = 'round_has_started',
  RoundHasEnded = 'round_has_ended',
  AddSubscriptionToUserAccount = 'add_subscription_to_user_account',
  AddAnotherSubscriptionToUserAccount = 'add_another_subscription_to_user_account',
}

export enum Roles {
  Unknown = 'unknown_user',
  User = 'user',
  Developer = 'developer',
  BusinessAdministrator = 'business_administrator',
  DeveloperBA = 'developer_business_administrator',
  SystemAdministrator = 'system_administrator',
}

export enum PopupTypes {
  None = '',
  Error = 'error_popup',
  Success = 'success_popup',
  Invite = 'invite_popup',
  Join = 'join_popup',
  JoinComment = 'join_comment_popup',
  InviteComment = 'invite_comment_popup',
  Verification = 'confirm_verification_popup',
  DeleteAllianceML = 'delete_ml_from_alliance_popup',
  DeleteML = 'delete_ml_popup',
  DeleteWeight = 'delete_weight_popup',
  DeleteAttachment = 'delete_attachment_popup',
  Logout = 'logout_popup',
  LeaveOpen = 'leave_open_popup',
  LeaveClose = 'leave_close_popup',
  ActivateAlliance = 'activate_alliance_popup',
  DeactivateAlliance = 'deactivate_alliance_popup',
  DeleteAlliance = 'delete_alliance_popup',
  AddOrEditWeight = 'add_or_edit_weight_popup',
  AddAnyModelToSpecificAlliance = 'add_any_model_to_specific_alliance',
  AddSpecificModelToAnyAlliance = 'add_specific_model_to_any_alliance',
  AddCurrentModel = 'add_current_model',
  AddWeight = 'add_weight_via_alliance',
  ModelToAllianceApplication = 'model_to_alliance_application',
  WeightToAllianceApplication = 'weight_to_alliance_application',
  BaConfirm = 'ba_confirm',
  WeightInAlliancePreview = 'weight_in_alliance_preview',
  Feedback = 'feedback_popup',
  CancelOrganizationAdd = 'cancel_organization_add',
  CancelDataEdit = 'cancel_data_edit',
  AddProjectToPortfolio = 'add_project_to_portfolio',
  EditProject = 'edit_project',
  ProjectDetailedInfo = 'project_detailed_info',
  DeleteProject = 'delete_project',
  DetailedWeight = 'detailed_weight',
  ShowEmployeeMetricsPopup = 'employee_metrics_popup',
  StartFL = 'start_FL',
  StartPartialAggregation = 'start_partial_aggregation',
  EditAggregatedWeight = 'edit_aggregated_weight',
  DeleteFLFile = 'delete_fl_file',
  StartOnboarding = 'start_onboarding_popup',
  WeightForBuildingImage = 'weight_for_building_image',
  PaidFLWarning = 'paid_fl_warning',
  DockerImageGuide = 'docker_image_guide_popup',
  DockerImageSubscriptionExpiredSoon = 'docker_image_subscription_expired_soon',
  PauseAppealPopup = 'pause_appeal_popup',
  ExcludePopup = 'exclude_popup',
  AddCurrentModelToCreatedAlliance = 'add_current_model_to_created_alliance_popup',
  ProlongRound = 'prolong_round'
}

export type AccountType = {
  isAuth: boolean | null;
  email: string | null;
  isConfirmed: boolean | null;
  id: number | null;
  isCookieAccepted: boolean;
  isStaff: boolean;
};

export type MenuCounter = {
  directory: number;
};

export type SettingsType = {
  notificationsSettings: NotificationsSettingsType;
  accountNotificationsSettings: AccountNotificationsSettingsType;
};
