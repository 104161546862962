import { FAILURE, request, SUCCESS } from '../utils/fetchHandler';
import { AuthByTokenResponse, LoginCheckResponse, LoginResponse } from './auth/types';

interface IUserData {
  email: string;
  password: string;
  recaptcha: string;
}

export const logIn = async (
  userData: IUserData,
): Promise<
  | { status: typeof SUCCESS; payload: LoginResponse; code: number | undefined }
  | { status: typeof FAILURE; payload: { detail: string }; code: number | undefined }
> => {
  const { status, payload, code } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/login/`,
    { credentials: 'include' },
    'POST',
    JSON.stringify(userData),
  );

  return { status, payload, code };
};

export const authByToken = async (
  token: string | null,
): Promise<{ status: string; payload: AuthByTokenResponse }> => {
  const { payload, status } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/?token=${token}`,
    {
      credentials: 'include',
    },
  );

  return { payload, status };
};

export const loginCheck = async (): Promise<{ status: string; payload: LoginCheckResponse }> => {
  const { payload, status } = await request(`${process.env.REACT_APP_BACK_END}/auth/login-check/`, {
    credentials: 'include',
  });

  return { payload, status };
};
