import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { alliancesApi } from '../../../../../api/alliancesApi';
import { Button } from '../../../../../ui';
import { Form } from '../../AddModelToAnyAlliancePopup/AddAlliance/Form/Form';
import { PropTypes } from './types';

import styles from './AddCreatedAlliance.module.scss';

export const AddCreatedAlliance = ({ model, onSubmit, onBack, onCheck, isValid }: PropTypes) => {
  const { t } = useTranslation();
  const getAvailableAlliances = alliancesApi.useGetAvailableListCounterQuery;
  const { data: adminAllianceCounter } = getAvailableAlliances({
    id: model,
    state: 'own-alliances',
  });

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div className={cn(styles.tab, styles.tabSelected)}>
          <span className={styles.label}>
            {t('popups.addAnyModelToSpecificAlliance.tabs.created.tabName')}
          </span>
          <span className={styles.counter}>{adminAllianceCounter ?? 0}</span>
        </div>
      </div>
      <Form onCheck={onCheck} count={adminAllianceCounter ?? 0} id={model} isControlled />
      <div className={styles.buttons}>
        <Button style="secondary" size="small" onClick={onBack}>
          {t('buttons.back')}
        </Button>
        <Button size="small" onClick={onSubmit} isDisabled={!isValid}>
          {t('buttons.add')}
        </Button>
      </div>
    </div>
  );
};
