import { FAILURE, SUCCESS, request } from '../utils/fetchHandler';
import { RegistrationCheckResponse, RegisterResponse } from './auth/types';

interface IRegistrationData {
  email: string;
  password: string;
  password_confirmation: string;
  recaptcha: string;
}

export const userRegister = async (
  userData: IRegistrationData,
): Promise<
  | { status: typeof SUCCESS; payload: RegisterResponse }
  | { status: typeof FAILURE; payload: { email: string } }
> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/register/`,
    { credentials: 'include' },
    'POST',
    JSON.stringify(userData),
  );

  return { status, payload };
};

export const resendAuth = async (): Promise<{ status: string }> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/resend/`,
    { credentials: 'include' },
    'POST',
  );

  return { status };
};

export const checkResend = async (): Promise<{
  status: string;
  payload: { remaining: string };
}> => {
  const { status, payload } = await request(`${process.env.REACT_APP_BACK_END}/auth/resend/`, {
    credentials: 'include',
  });
  return { status, payload };
};

export const checkRegistration = async (
  email: string,
): Promise<{ payload: RegistrationCheckResponse }> => {
  const { payload } = await request(
    `${process.env.REACT_APP_BACK_END}/auth/registration-check/?email=${email}`,
    {
      credentials: 'include',
    },
  );

  return { payload };
};
