import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Reaptcha from 'reaptcha';

import { Button, PasswordInput, CreatePasswordInput, Input } from '../../../../ui';
import { FormNotice } from '../FormNotice/FormNotice';
import { LabeledCheckbox } from './LabeledCheckbox/LabeledCheckbox';

import { signInSchema } from '../../../../schemas/validationSchemas';
import { useAppDispatch, useAppSelector, useToggle } from '../../../../hooks';
import { checkChars, getRating } from '../../../../utils/checkPassword';
import { userRegister } from '../../../../api/register';
import { sitekey } from '../LogInForm/LogInForm';
import { LOG_IN_PATH } from '../../../../router';
import { isError } from '../../../../utils/formatData';
import { accountSelector, openErrorPopup } from '../../../../store';
import { isCaptchaActive } from '../../../../utils';

import styles from '../Form.module.scss';

export const SignInForm = () => {
  const { t } = useTranslation();
  const [isRegistered, setRegistered] = useState<boolean>(false);
  const [isHintShown, setHintShown] = useState<boolean>(false);
  const { state: isPasswordShown, toggle: togglePassword } = useToggle<boolean>(false);
  const { state: isConfirmPasswordShown, toggle: toggleConfirmPassword } =
    useToggle<boolean>(false);
  const [token, setToken] = useState<string>(isCaptchaActive ? '' : 'token');
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    setError,
    getValues,
    formState: { isValid, errors },
    register,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(signInSchema),
    mode: 'onChange',
  });
  const values = watch(['password', 'passwordRepeat']);
  const [passwordValue, passwordRepeatValue] = values;

  const captcha = useRef<null | Reaptcha>(null);

  const { isCookieAccepted } = useAppSelector(accountSelector);

  const onSubmit: SubmitHandler<FieldValues> = async ({ email, password }) => {
    const userData = {
      email,
      password,
      password_confirmation: password,
      accept_cookies_policy: Boolean(document.cookie),
      recaptcha: token,
    };

    if (!isCookieAccepted) {
      return;
    }
    const { status, payload } = await userRegister(userData);
    if (status === 'failure') {
      if (payload.email) {
        setError('email', { type: 'custom', message: payload.email });
      } else {
        dispatch(openErrorPopup());
      }
      if (captcha.current) captcha.current.reset();
      setToken('');
    }
    if (status !== 'failure') {
      setRegistered(true);
    }
  };

  const handleChange = () => {
    if (passwordRepeatValue) trigger('passwordRepeat');
  };

  const handleVerify = (token: string) => {
    setToken(token);
  };

  const handleExpire = () => {
    setToken('');
  };

  return (
    <>
      {!isRegistered ? (
        <form
          className={styles.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2 className={styles.title}>{t('pages.authPage.singInForm.title')}</h2>
          <div className={styles.subtitle}>
            <Trans
              i18nKey="pages.authPage.singInForm.subtitle"
              components={{
                LoginLink: <NavLink to={`/${LOG_IN_PATH}`} />,
              }}
            />
          </div>
          <div className={styles.container}>
            <Input
              id="email"
              label={t('pages.authPage.singInForm.labels.email')}
              register={register}
              helperText={errors.email?.message}
              isDisabled={false}
              maxLength={50}
            />
            <CreatePasswordInput
              id="password"
              label={t('pages.authPage.singInForm.labels.password')}
              type={isPasswordShown ? 'text' : 'password'}
              onClick={togglePassword}
              register={register}
              helperText={errors.password?.message}
              value={passwordValue}
              rating={getRating(passwordValue)}
              checkedValue={checkChars(passwordValue)}
              isHintShown={isHintShown}
              setHintShown={setHintShown}
              handleChange={handleChange}
            />
            <PasswordInput
              id="passwordRepeat"
              label={t('pages.authPage.singInForm.labels.confirmPassword')}
              type={isConfirmPasswordShown ? 'text' : 'password'}
              onClick={toggleConfirmPassword}
              register={register}
              helperText={errors.passwordRepeat?.message}
              value={getValues('passwordRepeat')}
            />
          </div>
          <div className={styles.checkbox}>
            <LabeledCheckbox register={register} />
          </div>
          <div className={styles.captcha}>
            <Reaptcha
              ref={captcha}
              sitekey={sitekey}
              onVerify={recaptchaResponse => {
                handleVerify(recaptchaResponse);
              }}
              onExpire={handleExpire}
            />
          </div>
          <Button isDisabled={isError(errors) || !isValid || !token} type="submit">
            {t('buttons.continue')}
          </Button>
        </form>
      ) : (
        <FormNotice
          noticeType="singIn"
          email={getValues('email')}
          canBeClosed={false}
          isRecovery={false}
        />
      )}
    </>
  );
};
